
import './App.scss';

import React, { FC, useEffect } from 'react';
import AppLayout from './layout/AppLayout';
import configureStore from '../src/redux/core/store.config';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  EndSessionRequest,  PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
    auth: {
        clientId: "44069b9c-3bfd-4577-8f93-b6424be88c39",
        redirectUri: "/",
        authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

const instance = new PublicClientApplication(configuration);
instance.initialize()

const useAutoLogout = (logoutAfterMinutes = 30) => {
    useEffect(() => {
      let timeoutId: any;
  
      const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          // Define the logout request
          const logoutRequest: EndSessionRequest = {
            postLogoutRedirectUri: '/', 
          };
  
          // Perform logout with redirect
          instance.logoutRedirect(logoutRequest);
        }, logoutAfterMinutes * 60 * 1000); // Convert minutes to milliseconds
      };
  
      // Events to reset the timer
      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer;
      window.ontouchstart = resetTimer;
      window.onclick = resetTimer;
  
      // Set the initial timer
      resetTimer();
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, [logoutAfterMinutes]);
  };
  
  
const App: FC = () => {
    useAutoLogout(30);
    return (
        <MsalProvider instance={instance}>
            <Provider store={configureStore()}>
                <BrowserRouter>
                    <AppLayout />
                </BrowserRouter>
            </Provider>
        </MsalProvider>
    );
};

export default App;


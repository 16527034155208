import React, { useState } from 'react'
import { MotifFormField, MotifSearch } from '@ey-xd/motif-react'

const Search = (props: any) => {

    const [value, setValue] = useState('')
    return (
        <MotifFormField>
            <MotifSearch
                onChange={(e: any) => setValue(e.target.value)}
                placeholder="Search for..."
                value={value}
                {...props}
            />
        </MotifFormField>
    )
}

export default Search
import { configureStore, EnhancedStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk';
import rootReducers from '../reducers/rootReducers';

let store: EnhancedStore;
const configureAppStore = (): EnhancedStore => {
    store = configureStore({
        reducer: rootReducers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
        devTools: true
    });
    return store;
};

export default configureAppStore;

import React, { useState, useEffect } from 'react'
import { MotifIcon, MotifToast, MotifNotification } from '@ey-xd/motif-react'
import { alertIcErrorOutline24px } from "@ey-xd/motif-react/assets/icons";
import Modal from '../../../common/components/Modal/Modal'
import Toast from '../../../common/components/Toast/Toast'
import './DeleteModal.scss'

const DeleteModal = ({ isVisible, setIsVisible, report, onConfirmClick }: any) => {

    const handleonfirmClick = () => {
        onConfirmClick && onConfirmClick()
    }

    return (
        <div>
            <Modal
                isVisible={isVisible}
                setVisible={setIsVisible}
                isDeleteModal
                onConfirmClick={handleonfirmClick}
                onCancelClick={() => setIsVisible(false)}
                headerContent={
                    <div className="deleteModalHeader" id="dialog-title">
                        <MotifIcon
                            src={alertIcErrorOutline24px}
                            className="deleteModalHeader__icon"
                        />
                        <div>Delete {report.reportName}?</div>
                    </div>
                }
                bodyContent={
                    <div className='deleteModalContent'>
                        <div className='deleteModalContent__text'>
                            Are you sure you want to delete this report? This action cannot be undone.
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default DeleteModal

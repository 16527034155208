import React, { useState } from 'react'
import './ReportSection.scss'
import {
    contentIcContentCopy24px,
    actionIcInfoOutline24px
} from "@ey-xd/motif-react/assets/icons";
import TextArea from '../../../common/components/TextArea/TextArea'
import Slider from '../../../common/components/Slider/Slider'
import {
    MotifIcon,
    MotifToast,
    MotifButton
} from "@ey-xd/motif-react";


function ReportSection({ id, defaultPrompt, defaultContent, defaultRandomness, loading, generateSection }: any) {
    const [randomness, setRandomness] = useState(defaultRandomness * 100)
    const [prompt, setPrompt] = useState(defaultPrompt)
    const [isCopied, setIsCopied] = useState(false)
    const onRandomnessChange = (e: any) => {
        setRandomness(e.target.value)
    }

    return (
        <div className="reportSectionWrapper">
            <div className="reportSection">
                <div className="reportSectionCopy">
                    <MotifButton
                        size="medium"
                        variant="text"
                        type="button"
                        className="saveReport"
                        disabled={!defaultContent}
                        onClick={() => {
                            navigator.clipboard.writeText(defaultContent)
                            setIsCopied(true)
                        }}
                    >
                        <MotifIcon className="copyIcon" src={contentIcContentCopy24px} />
                        <span className="buttonText">Copy</span>
                    </MotifButton>
                </div>
                {isCopied && <MotifToast onClose={() => setIsCopied(false)}>Content copied to clipboard</MotifToast>}
                <div className="reportSectionContent">
                    <TextArea rows="20" value={defaultContent} disabled />
                    <div className="sectionPrompt">
                        <div className="sectionPrompt__header">
                            <div className="sectionPrompt__header__text">Section Prompt</div>
                            <MotifIcon className="sectionPrompt__header__icon" src={actionIcInfoOutline24px} />
                        </div>
                        <TextArea label={'Prompt'} rows="5" value={prompt} onChange={(e: any) => setPrompt(e.target.value)} />
                    </div>
                </div>
                <div className='randomnessWrapper'>
                    <div className="randomness">
                        <div className='randomness__header'>
                            <div>Randomness (Temperature Parameter)</div>
                            <MotifIcon className="randomness__header__icon" src={actionIcInfoOutline24px} />
                        </div>
                        <div className='randomness__control'>
                            <Slider showMinMax={false} defaultValue={20} onChange={onRandomnessChange} step={10} />
                            <div className="sliderValue" style={{ position: 'relative', left: `${(randomness * 0.95)}%` }}>{randomness / 100}</div>
                        </div>
                    </div>
                </div>
                <div className="submitSectionWrapper">
                    <MotifButton
                        size="medium"
                        variant="primary"
                        type="button"
                        onClick={() => generateSection(id, prompt, randomness)}
                    >
                        Generate Section
                    </MotifButton>
                </div>
            </div>
        </div>
    )
}

export default ReportSection
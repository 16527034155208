import React, { useState } from 'react'
import {
    MotifFormField,
    MotifLabel,
    MotifInput,
} from "@ey-xd/motif-react";

const Input = ({ label, onChange, ...props}: any) => {

    const [value, setValue] = useState('')

    const handleOnChange = (e: any) => {
        setValue(e.target.value)
        onChange && onChange(e)
    }
    return (
        <MotifFormField>
            <MotifLabel>{label}</MotifLabel>
            <MotifInput value={value} onChange={handleOnChange} {...props} />
        </MotifFormField>
    )
}

export default Input
import React from 'react'
import ReactDOM from 'react-dom';
import './Modal.scss'
import { MotifButton, MotifIcon } from '@ey-xd/motif-react'
import { navigationIcClose24px } from '@ey-xd/motif-react/assets/icons';

const Modal = ({
  isVisible,
  setVisible,
  headerContent,
  bodyContent,
  isDeleteModal,
  onConfirmClick,
  onCancelClick
}: any) => {
  const modal = (
    <div className="modal">
      <div
        className="modal__wrapper"
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <div className="modal__headerWrapper">
          {headerContent}
          <button onClick={() => setVisible(false)} className={'modal__close'}>
            <MotifIcon src={navigationIcClose24px} />
          </button>
        </div>
        <div className={'modal__content'}>
          {bodyContent}
        </div>
        <div className="button-wrap">
          <MotifButton
            size="medium"
            variant="secondary"
            type="button"
            className="button-wrap__cancel"
            onClick={() => {
              onCancelClick && onCancelClick()
              setVisible(false)
            }}
          >
            Cancel
          </MotifButton>
          <MotifButton
            size="medium"
            variant="primary"
            type="button"
            className={`button-wrap__ok ${isDeleteModal ? 'button-wrap__ok--delete' : ''}`}
            onClick={() => {
              onConfirmClick && onConfirmClick()
              setVisible(false)
            }}
          >
            {isDeleteModal ? 'Delete': 'Save' }
          </MotifButton>
        </div>
      </div>
      <div className={!isVisible ? 'bglayoutHide' : 'bglayoutShow'}></div>
    </div>
  )
  return !isVisible ? null : ReactDOM.createPortal(modal, document.body);
}

export default Modal

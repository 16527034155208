import { useState } from 'react'
import { MotifIcon, MotifButton, MotifDropdownPortal, MotifDropdownItem } from '@ey-xd/motif-react'
import { actionIcDelete24px, fileIcFileDownload24px, imageIcEdit24px, alertIcErrorOutline24px, actionIcHelpOutline24px } from "@ey-xd/motif-react/assets/icons";
import './Actions.scss'

const Actions = ({
    id,
    setEditModalVisible,
    setDeleteModalVisible,
    setDeleteItemId,
    setEditItemId,
    downloadReport
}: any) => {
    const [downloadVisible, setDownloadVisible] = useState(false)

    return (
        <div className="reportLibraryActions">
            <MotifButton
                size="small"
                variant="text"
                type="button"
                onClick={() => {
                    setDeleteItemId(id)
                    setDeleteModalVisible(true)}
                }
            >
                <MotifIcon src={actionIcDelete24px} style={{ width: 20 }}/>
            </MotifButton>
            <MotifButton
                size="small"
                variant="text"
                type="button"
                onClick={() => {
                    setEditItemId(id)
                    setDownloadVisible(!downloadVisible)
                    downloadReport(id)
                }}
            >
                <MotifIcon src={fileIcFileDownload24px} style={{ width: 20 }} />
            </MotifButton>
            <MotifButton
                size="small"
                variant="text"
                type="button"
                onClick={() => {
                    setEditModalVisible(true)
                    setEditItemId(id)
                }}
                style={{ visibility: 'hidden' }}
            >
                <MotifIcon src={imageIcEdit24px} style={{ width: 20 }} />
            </MotifButton>
        </div>
    )
}

export default Actions

import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsalAuthentication, useMsal } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MotifProgressLoader } from '@ey-xd/motif-react'
import SideBar from '../components/SideBar/SideBar'
import Header from 'components/Header/Header';
import ReportGenerator from 'components/ReportGenerator/ReportGenerator';
import ReportLibrary from 'components/ReportLibrary/ReportLibrary';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import './AppLayout.scss'

const AppLayout = () => {
    const sideCollapsed = useSelector((state: any) => state?.sideBar?.collapsed) || false;
    const isAuthenticated = useIsAuthenticated();
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect);
    const { instance, accounts, inProgress } = useMsal();

    if(!isAuthenticated) {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0],
        };
        if(accounts[0]) {
            instance.acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken
                    sessionStorage.setItem('accessToken', accessToken)
                    sessionStorage.setItem('user', accounts[0].name || '')
                })
                .catch((e) => {
                    console.error(e)
                })
        }

        const loadingStartUp = inProgress === 'startup'
        const loadingLogin = inProgress === 'login'
        const loadingRedirect = inProgress === 'handleRedirect'
        const loadingLogout = inProgress === 'logout'
        const inProgressNone = inProgress === 'none'
        return (
            <div>
                {loadingLogin && <p>Logging you in</p>}
                {loadingRedirect && <MotifProgressLoader show={true} />}
                {loadingLogout && <p>Logging you out</p>}
                {!loadingLogin && !loadingStartUp && !loadingRedirect && !loadingLogout && <p>You are not authorized to login</p>}
            </div>
        )
    }

    return (
        <div className='wrapper'>
            <ErrorBoundary>
                <Header />
            </ErrorBoundary>
            <ErrorBoundary>
                <div className="content">
                    <SideBar collapsed={sideCollapsed}></SideBar>
                    <div className="main">
                        <Routes>
                            <Route
                                path={'/'}
                                element={<Navigate to={'/reportGenerator'} />}
                            />
                            <Route path={`reportGenerator/*`} element={<ReportGenerator />} />
                            <Route path={`reportLibrary/*`} element={<ReportLibrary />} />
                        </Routes>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}

export default AppLayout;

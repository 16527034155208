//import Type from './type';

/**
 * Encodes a URI.
 *
 * @param value URI to be encoded.
 *
 * @returns Encoded URI.
 */
const encode = function encode(value: string): string {
   return encodeURIComponent(value);
};

/**
 * Decodes a URI.
 *
 * @param value URI to be decoded. Replaces +, @, : and $.
 *
 * @returns URI.
 */
const decode = function decode(value: string): string {
   return decodeURIComponent(
      value
         .replace(/\+/g, '%20')
         .replace(/@/g, '%40')
         .replace(/:/g, '%3A')
         .replace(/\$/g, '%24')
   );
};

const getBaseUrl = (url: string): string => {
   return url;
};

const transformUrl = (url: string) => {
   return url;
};

const parseQuery = (data: any, mode?: boolean) => {
   if (!(data instanceof Object)) {
      return data;
   }

   data = JSON.stringify(data);

   if (mode === true) {
      return data;
   }
   return (function(exp) {
      data = data.replace(exp.wild, function(
         word: any,
         $1: any,
         $2: any,
         $3: any,
         $4: any,
         $5: any
      ) {
         return [$2, '=', $5].join('');
      });

      return data.replace(exp.trim, '').replace(exp.place, '&');
   })(
      // Exp
      {
         // Wild
         wild: new RegExp(
            /(")?([\w\-\!\@\#\$\%\^\&\*\+\(\)\{\}\[\]\:\;\,\.]+)(")?:(")?([\w\-\!\@\#\$\%\^\&\*\+\(\)\{\}\[\]\:\;\,\.]+)(")?/g
         ),
         // Trim
         trim: new RegExp(/^{|}$/g),
         // Place
         place: new RegExp(/\,/g)
      }
   );
};

export default {
   encode,
   decode,
   transformUrl,
   parseQuery
};

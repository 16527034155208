import React from 'react'
import './ReportDetails.scss'

const ReportDetails = ({ reportDetails }: any) => {

  const getDate = (date: any) => {
    return new Date(date).toLocaleDateString()
  }
  const getTime = (date: any) => {
    return new Date(date).toLocaleTimeString()
  }
  return (
    <div className="reportDetails">
        <div className="reportDetails__item">
            <div className="reportDetails__item__title">Report Name</div>
            <div className="reportDetails__item__text">{reportDetails.reportName}</div>
        </div>
        <div className="reportDetails__item">
            <div className="reportDetails__item__title">Number of inputs </div>
            <div className="reportDetails__item__text">{reportDetails.noOfInputs}</div>
        </div>
        <div className="reportDetails__item">
            <div className="reportDetails__item__title">Date</div>
            <div className="reportDetails__item__text">{getDate(reportDetails.date)}</div>
        </div>
        <div className="reportDetails__item">
            <div className="reportDetails__item__title">Time</div>
            <div className="reportDetails__item__text">{getTime(reportDetails.date)}</div>
        </div>
    </div>
  )
}

export default ReportDetails
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from "@azure/msal-react";
import {
    MotifAvatar,
    MotifHeader,
    MotifHeaderLogo,
    MotifIcon,
    MotifMenu,
    MotifButton,
    MotifMenuItem,
    MotifMenuItemGroup,
    MotifDropdownPortal,
    MotifDropdownItem,
    MotifVerticalNavigationHeaderButton as MotifVerticalNavHeaderButton,
} from '@ey-xd/motif-react';
import { actionIcExitToApp24px, socialIcLocationCity24px } from '@ey-xd/motif-react/assets/icons';
import { setSideBarCollapsed } from '../../redux/actions/SideBar/sideBar'
import EYLogo from '../../assets/media/images/ey-logo.png'
import './Header.scss'

const Header = () => {

  const [userDetailMenu, setUserDetailMenu] = useState<boolean>(false);
  const sideCollapsed = useSelector((state: any) => state?.sideBar?.collapsed) || false;
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const userName = sessionStorage.getItem('user');

  const handleSignOut = () => {
    instance.logoutRedirect();
  }

  return (
    <div className="headerWrapper">
      <MotifHeader
        appHeaderName="AI Threats Reporting"
        logo={
          <MotifHeaderLogo>
            <div>
              <img src={EYLogo} alt='EY Logo - Home' width={28} className="eyLogo"/>
            </div>
          </MotifHeaderLogo>
        }
        leftComponents={
          <MotifVerticalNavHeaderButton class="test" onClick={() => dispatch(setSideBarCollapsed(!sideCollapsed))} />
        }
        iconsContainer={
          <MotifDropdownPortal
              open={userDetailMenu}
              hideArrow={false}
              trigger={
                  <MotifButton
                      aria-label="Profile dropdown menu"
                      aria-describedby="Profile dropdown menu"
                      type="button"
                      className="header__avatar"
                      onClick={() => setUserDetailMenu(!userDetailMenu)}
                  >
                      <MotifAvatar
                          userName={userName}
                          email={"Test Email"}
                          status={'online'}
                      />
                  </MotifButton>
              }>
              <MotifDropdownItem className="header__item" onClick={() => {}}>
                  <MotifAvatar
                      userName={userName}
                      email={"Test Email"}
                      status={'online'}
                  />
                  <div className="header__info">
                    <div className="header__displayname">{userName}</div>
                  </div>
              </MotifDropdownItem>
              <MotifDropdownItem className="header__item" onClick={()=>{}}>
                  <span className="header__item__role">Role:</span>
                  <span>Admin</span>
              </MotifDropdownItem>
              <MotifDropdownItem className="header__item" onClick={handleSignOut}>
                  <MotifIcon src={actionIcExitToApp24px} />
                  <span>Log out</span>
              </MotifDropdownItem>
          </MotifDropdownPortal>
        }
      />
    </div>
  )
}

export default Header

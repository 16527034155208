import { BaseHTTPClient } from '../api/http-client.service';
import { APIType } from '../api/configuration';
import { ApiConfig } from '../api/api-service/api-config.model';

export class ReportLibraryService extends BaseHTTPClient {
    public static async getReportLibrary() {
        return this.sendToGateway({
            url: `${process.env.REACT_APP_API_URL}/reports`,
            method: APIType.GET,
        });
    }
    public static async saveReport(payload: any) {
        return this.sendToGateway({
           url: `${process.env.REACT_APP_API_URL}/reports/save`,
           method: APIType.POST,
           data: payload
        });
    }
    public static async deleteReport(payload: any) {
        return this.sendToGateway({
           url: `${process.env.REACT_APP_API_URL}/reports/delete`,
           method: APIType.DELETE,
           data: payload,
        });
    }
    public static async downloadReport(payload: any) {
        return this.sendToGateway({
           url: `${process.env.REACT_APP_API_URL}/reports/download`,
           method: APIType.POST,
           data: payload,
        });
    }
}

import { BaseHTTPClient } from '../api/http-client.service';
import { APIType } from '../api/configuration';
import { ApiConfig } from '../api/api-service/api-config.model';

export class ReportGeneratorService extends BaseHTTPClient {
   public static async addParsedItem(payload: any) {
      return this.sendToGateway({
         url: `${process.env.REACT_APP_API_URL}/documents/parse`,
         method: 'UPLOAD',
         data: payload,
      });
   }
   public static async getReportGenerator(payload: any) {
      return this.sendToGateway({
         url: `${process.env.REACT_APP_API_URL}/documents/upload`,
         method: APIType.POST,
         data: payload,
      });
   }
   public static async updateReportSections(payload: any) {
      return this.sendToGateway({
         url: `${process.env.REACT_APP_API_URL}/generate_execution_flow`,
         method: APIType.POST,
         data: payload,
      });
   }
}

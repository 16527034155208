import { Dispatch } from 'redux'
import { ReportGeneratorService } from '../../../services/reportGenerator.service';

export const generateReport: any = (requestParams: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'GENERATE_REPORT_LOADING' });
        const res = await ReportGeneratorService.getReportGenerator({ data: requestParams });
        dispatch({ type: 'GENERATE_REPORT_SUCCESS', payload: res.value });
    } catch(e: any) {
        dispatch({ type: 'GENERATE_REPORT_ERROR' });
        throw e;
    }
}

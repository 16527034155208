const defaultState: any = {}
const reportReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'GENERATE_REPORT_LOADING':
            return { ...state, loading: true };
        case 'GENERATE_REPORT_SUCCESS':
            return { ...state, data: action.payload, loading: false };
        case 'GENERATE_REPORT_ERROR':
            return { ...state, error: true, loading: false };
        default:
            return state;
    }
}

export default reportReducer;

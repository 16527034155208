import { Component, ReactElement, ReactNode } from 'react';
import './ErrorBoundary.scss'

declare const process: NodeJS.Process;

type ErrorBoundaryProps = {
	children: ReactNode;
	fallBack?: ReactNode | ReactElement;
};

type State = {
	hasError: boolean;
};

type ErrorInfo = {
	componentStack: string;
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
	state: State = {
		hasError: false
	};

	static getDerivedStateFromError(): State {
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: ErrorInfo): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			console.log('Error Boundary', error);
			console.log('Error Info', info);
		}
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallBack || <div className="errorContent">Error in this component...!!!</div>;
		}
		return this.props.children;
	}
}

import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MotifIcon, MotifToast, MotifNotification } from '@ey-xd/motif-react'
import { actionIcHelpOutline24px } from "@ey-xd/motif-react/assets/icons";
import Modal from '../../../common/components/Modal/Modal'
import './EditModal.scss'

const EditModal = ({ isVisible, setIsVisible, report, onConfirmClick }: any) => {

    const navigate = useNavigate()
    const handleConfirmClick = () => {
        onConfirmClick && onConfirmClick(report)
        navigate('/reportGenerator')
    }
    return (
        <Modal
            isVisible={isVisible}
            setVisible={setIsVisible}
            headerContent={
                <div className="editModalHeader" id="dialog-title">
                    <MotifIcon
                        src={actionIcHelpOutline24px}
                        className="editModalHeader__icon"
                    />
                    <div>Open “{report.reportName}” in  Report Generator?</div>
                </div>
            }
            bodyContent={
                <div className='editModalContent'>
                    <div className='editModalContent__text'>
                        Report will be opened in Report Generator, where you can edit it and add or remove data sources.
                    </div>
                </div>
            }
            onConfirmClick={handleConfirmClick}
        />
    )
}

export default EditModal

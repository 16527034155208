const defaultState: any = false;

const sideBarReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'SET_SIDEBAR_COLLAPSED':
            return { ...state, collapsed: action.payload };
        default:
            return state;
    }
}

export default sideBarReducer;

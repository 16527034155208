import React from 'react'
import { MotifToast } from '@ey-xd/motif-react'
import './Toast.scss'

const Toast = ({ visible, setVisible, children, variant }: any) => {
  return (
    <div className='toastWrapper'>
        {visible && (
            <MotifToast
                actionName=""
                onClose={() => {setVisible(false)}}
                position="top"
                variant={variant}
            >
                {children}
            </MotifToast>
        )}
    </div>
  )
}

export default Toast

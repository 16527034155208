import React, { useState } from 'react'
import { MotifIcon } from "@ey-xd/motif-react";
import { contentIcSave24px } from "@ey-xd/motif-react/assets/icons";
import Modal from '../../../common/components/Modal/Modal'
import Input from '../../../common/components/Input/Input'
import './SaveReportModal.scss'

const SaveReportModal = ({ visibility, setVisibility }: any) => {
    return (
      <Modal
        isVisible={visibility}
        setVisible={setVisibility}
        headerContent={
          <div className="saveModalHeader" id="dialog-title">
            <MotifIcon
              src={contentIcSave24px}
              className="saveModalHeader__icon"
            />
            <div>Saving Report to Libarary</div>
          </div>
        }
        bodyContent={
          <div className='saveModalContent'>
            <div className='saveModalContent__label'>Saving Report</div>
            <div className='saveModalContent__text'>Please provide unique name for your report. When you save report it will be available in Report’s Library.</div>
            <div className='saveModalContent__label'>Report Name (required)</div>
            <div>
              <Input label={'Report Name'}/>
            </div>
          </div>
        }
      />
    )
}

export default SaveReportModal
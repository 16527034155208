import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    MotifVerticalNavigation,
    MotifVerticalNavigationContent,
    MotifVerticalNavigationMenu,
    MotifVerticalNavigationMenuItem,
    MotifVerticalNavigationSubmenu,
    MotifVerticalNavigationFooter,
    MotifVerticalNavigationContextSwitcher,
    MotifIcon,
    MotifButton
} from '@ey-xd/motif-react';
import { actionIcSearch24px, actionIcTurnedInNot24px, hardwareIcPhonelink24px, socialIcPages24px } from "@ey-xd/motif-react/assets/icons";
import './SideBar.scss';
import { Motif_Ey, Iconoir_Computer } from '@ey-xd/motif-icon'
import ReportLibraryLogo from '../../assets/media/images/repository.png'
import ReportGeneratorLogo from '../../assets/media/images/vector.png'

const SideBar = ({ collapsed }: any) => {

    const location = useLocation();
    const navigate = useNavigate();

    const redirectSelectedRoute = (path: string) => {
        navigate(path);
    };

    return (
        <div className={`sideNavWrapper ${collapsed ? 'collapsedWidth': ''}`}>
            <MotifVerticalNavigation>
                <MotifVerticalNavigationContent>
                    <MotifVerticalNavigationMenu>
                        <MotifVerticalNavigationMenuItem
                            icon={<img src={ReportGeneratorLogo} alt="Report Generator" width={20} />}
                            label="Report Generator"
                            selected={location?.pathname.includes('reportGenerator')}
                            onClick={() => redirectSelectedRoute('/reportGenerator')}
                        >
                            Report Generator
                        </MotifVerticalNavigationMenuItem>
                        <MotifVerticalNavigationMenuItem
                            icon={<img src={ReportLibraryLogo} alt="Report's Library" width={24} />}
                            label="Report's Library"
                            selected={location?.pathname.includes('reportLibrary')}
                            onClick={() => redirectSelectedRoute('/reportLibrary')}
                        >
                            Report's Library
                        </MotifVerticalNavigationMenuItem>
                    </MotifVerticalNavigationMenu>
                </MotifVerticalNavigationContent>
            </MotifVerticalNavigation>
        </div>
    )
}

export default SideBar;

import React, { useState } from 'react'
import {
    MotifFormField,
    MotifSlider,
} from "@ey-xd/motif-react";

function Input({ label, defaultValue = "", onChange, ...props}: any) {

    const [value, setValue] = useState(defaultValue)

    const handleOnChange = (e: any) => {
        setValue(e.target.value / 100)
        onChange && onChange(e)
    }
    return (
        <MotifFormField>
            <MotifSlider value={value} onChange={handleOnChange} {...props} />
        </MotifFormField>
    )
}

export default Input
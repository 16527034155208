const defaultState: any = []
const reportSectionReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'GET_REPORT_SECTIONS':
            return { ...state, data: action.payload };
        case 'GENERATE_REPORT_SECTIONS_LOADING':
            const reportSectionLoadingItem = [...state.data]
                .map((i: any) => i.id === action.payload ? ({ ...i, loading: true }): ({ ...i }))
            return { ...state, data: reportSectionLoadingItem };
        case 'GENERATE_REPORT_SECTIONS_SUCCESS':
            const reportSectionData = action.payload.data;
            const reportSectionId = action.payload.id;
            const updatedReportSections = [...state.data]
                .map((i: any) => i.id === reportSectionId ? ({ ...i, ...reportSectionData, loading: false, error: false }): ({ ...i }))
            return { ...state, data: updatedReportSections };
        case 'GENERATE_REPORT_SECTIONS_ERROR':
            const reportSectionErrorItem = [...state.data]
                .map((i: any) => i.id === action.payload ? ({ ...i, loading: false, error: true }): ({ ...i }))
            return { ...state, data: reportSectionErrorItem };
        default:
            return state;
    }
}

export default reportSectionReducer;

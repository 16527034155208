import React, { useState, useEffect } from 'react'
import './ReportLibrary.scss'
import { useDispatch, useSelector } from 'react-redux';
import { MotifTable, MotifProgressLoader, MotifTableCellRenderer, MotifIcon, MotifButton, MotifDropdownPortal, MotifDropdownItem } from '@ey-xd/motif-react'
import { actionIcDelete24px, fileIcFileDownload24px, imageIcEdit24px, alertIcErrorOutline24px, actionIcHelpOutline24px } from "@ey-xd/motif-react/assets/icons";
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation'
import { getReportLibrary, udpateReportLibrary, deleteReport } from '../../redux/actions/ReportLibrary/reportLibrary'
import DeleteModal from './DeleteModal/DeleteModal'
import EditModal from './EditModal/EditModal'
import Search from '../../common/components/Search/Search'
import Table from '../../common/components/Table/Table'
import Toast from '../../common/components/Toast/Toast'
import Actions from './Actions/Actions'

function ReportLibrary() {

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(-1)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editItemlId, setEditItemId] = useState(-1)
  const [searchValue, setSearchValue] = useState('')
  const [toastVisible, setToastVisible] = useState(false)
  const [toastData, setToastData] = useState<any>()

  const dispatch = useDispatch()
  const reportLibrary = useSelector((state: any) => state?.reportLibrary) || false
  const [filteredData, setFilteredData] = useState([])

  const handleDeleteReport = () => {
    dispatch(deleteReport(deleteItemId)).then(() => {
      setToastVisible(true)
      setToastData({
        header: "Successfully deleted",
        message: "Your have successfully deleted report. It won’t be available in Report’s Library any more.",
        variant: "success"
      })
    }).catch(() => {
      setToastVisible(true)
      setToastData({
        header: "Failed",
        message: "Failed to delete report. Please try again.",
        variant: "error"
      })
    })
    setDeleteItemId(-1)
  }

  const handleUdpateReport = (item: any) => {
    const newItem = { ...item }
    dispatch(udpateReportLibrary(newItem))
    setDeleteModalVisible(false)
    setEditItemId(-1)
  }

  useEffect(() => {
    dispatch(getReportLibrary())
  }, [])

  useEffect(() => {
    setFilteredData(reportLibrary?.data)
  }, [reportLibrary])

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value)
    const filtered = reportLibrary?.data?.filter((item: any) => item.reportName.toLowerCase().includes(e.target.value.toLowerCase())) || []
    setFilteredData(filtered)
  }

  const downloadReport = async (id: any) => {
    dispatch({ type: 'DOWNLOAD_REPORT_LOADING' });
    fetch(`${process.env.REACT_APP_API_URL}/reports/download`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: "POST",
      body: JSON.stringify({ "record_id": id }),
  }).then(resp => {
    if(resp.ok) {
      return resp.arrayBuffer()
    } else {
      setToastVisible(true)
      setToastData({
        header: "Failed",
        message: "Unable to download Report. Please try again.",
        variant: "error"
      })
      dispatch({ type: 'DOWNLOAD_REPORT_ERROR' });
      throw new Error('Something went wrong')
    }
  }).then(resp => {
      const fileName: any = filteredData.find((d: any) => d.Id === id)

      // set the blog type to final pdf
      const file = new Blob([resp], {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = !fileName?.reportName.includes('.pptx') ? fileName?.reportName + ".pptx" : fileName?.reportName;
      link.click();
      dispatch({ type: 'DOWNLOAD_REPORT_SUCCESS' });
      setToastVisible(true)
      setToastData({
        header: "Success",
        message: "Report downloaded successfully",
        variant: "success"
      })
    })
    .catch((e: any) => console.error(e))
  }

  return (
    <div className="reportLibraryWrapper">
      <MotifProgressLoader show={reportLibrary?.loading} />
      <div className="breadCrumbs">Reports</div>
      <div className="heading">
        <div className="title">Report Library</div>
        <div className="description">Welcome to the Report Library, your centralized hub for all generated Executive Summary Reports. Here you have the option to delete or download your tailored reports at your convenience.</div>
      </div>
      <div className="reportLibrary__content">
        <MotifTabNavigation defaultActiveKey={0}>
          <MotifTabControl>
            My Reports
          </MotifTabControl>
        </MotifTabNavigation>
        <Search
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search report name..."
        />
        <div className='reportLibrary__tableWrapper'>
          {filteredData?.length > 0 && (
            <MotifTable
              columnDefs={[
                {
                  field: 'reportName',
                  headerName: 'Report Name',
                  cellRenderer: 'nameCellRenderer',
                  sortable: true,
                  width: 480
                },
                {
                  field: 'dateCreated',
                  headerName: 'Date Created',
                  cellRenderer: 'dateCellRenderer',
                  sortable: true,
                  width: 200
                },
                {
                  field: 'dateModified',
                  headerName: 'Date Modified',
                  cellRenderer: 'dateCellRenderer',
                  sortable: true,
                  width: 200
                },
                {
                  field: 'owner',
                  cellRenderer: 'ownerCellRenderer',
                  headerName: 'Owner',
                  sortable: true,
                  width: 200
                },
                {
                  field: 'actions',
                  cellRenderer: 'actionsCellRenderer',
                  headerName: 'Actions',
                  sortable: true,
                  className: 'testActions'
                }
              ]}
              components={{
                nameCellRenderer: (p: any) => {
                  return <div className='cellReportName' title={p.value}>
                    {p.value}
                  </div>
                },
                actionsCellRenderer: (p: any) => (
                  <Actions
                    id={p.data.Id}
                    setDeleteItemId={setDeleteItemId}
                    setEditItemId={setEditItemId}
                    setEditModalVisible={setEditModalVisible}
                    setDeleteModalVisible={setDeleteModalVisible}
                    downloadReport={() => downloadReport(p.data.Id)}
                  />
                ),
                dateCellRenderer: (p: any) => (
                  <div>{p.value ? new Date(p.value).toLocaleDateString(): ""}</div>
                )
              }}
              onFilterChanged={function noRefCheck(){}}
              onGridReady={function noRefCheck(){}}
              onSortChanged={function noRefCheck(){}}
              rowHeight={50}
              pagination
              paginationPageSize="10"
              rowData={filteredData}
              suppressCellFocus
              suppressRowClickSelection
            />
          )}
        </div>
      </div>
      <DeleteModal
        report={filteredData?.find((data: any) => data.Id === deleteItemId) || {}}
        isVisible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
        onConfirmClick={handleDeleteReport}
      />
      <EditModal
        report={filteredData?.find((data: any) => data.Id === editItemlId) || {}}
        isVisible={editModalVisible}
        setIsVisible={setEditModalVisible}
        onConfirmClick={handleUdpateReport}
      />
      <Toast
        visible={toastVisible}
        setVisible={setToastVisible}
        children={
          <div>
            <div className={'toastHeader'}>{toastData?.header}</div>
            <div className={'toastContent'}>{toastData?.message}</div>
          </div>
        }
        variant={toastData?.variant}
      />
    </div>
  )
}

export default ReportLibrary

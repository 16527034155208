const defaultState: any = {}

const reportLibraryReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case 'GET_REPORT_LIBRARY_LOADING':
        case 'SAVE_REPORT_LOADING':
        case 'DELETE_REPORT_LOADING':
        case 'DOWNLOAD_REPORT_LOADING':
            return { ...state, loading: true };
        case 'GET_REPORT_LIBRARY_SUCCESS':
            return { ...state, data: action.payload, loading: false, error: false };
        case 'GET_REPORT_LIBRARY_ERROR':
        case 'SAVE_REPORT_ERROR':
        case 'DELETE_REPORT_ERROR':
        case 'DOWNLOAD_REPORT_ERROR':
            return { ...state, loading: false, error: true };
        case 'SAVE_REPORT_SUCCESS':
            return { ...state, loading: false, error: false, saveReport: action.payload };
        case 'DOWNLOAD_REPORT_SUCCESS':
            return { ...state, loading: false, error: false };
        case 'UPDATE_REPORT_LIBRARY':
            const updatedItems = [...state.data]
                .map((i: any) => {
                    if(i.id === action.payload.Id) {
                        return { ...action.payload }
                    } else {
                        return { ...i }
                    }
                })
            return { ...state, data: [...updatedItems] };
        case 'DELETE_REPORT_SUCCESS':
            const filteredItems = [...state.data]
                .filter((item: any) => item.Id !== action.payload);
            return { ...state, data: filteredItems, loading: false, error: false };
        default:
            return state;
    }
}

export default reportLibraryReducer;

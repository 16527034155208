import { combineReducers } from 'redux';
import reportLibraryReducer from './reportLibrary';
import reportGeneratorReducer from './reportGenerator';
import sideBarReducer from './sideBar';
import reportReducer from './report';
import reportSectionsReducer from './reportSections';
import reportDetailsReducer from './reportDetails';

export default combineReducers({
    sideBar: sideBarReducer,
    reportLibrary: reportLibraryReducer,
    reportGenerator: reportGeneratorReducer,
    report: reportReducer,
    reportSections: reportSectionsReducer,
    reportDetails: reportDetailsReducer
})

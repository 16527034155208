import { Dispatch } from 'redux'
import { ReportGeneratorService } from '../../../services/reportGenerator.service';

export const getReportSections: any = (reportSections: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'GET_REPORT_SECTIONS', payload: reportSections });
}

export const generateReportSections: any = (reportSection: any, id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'GENERATE_REPORT_SECTIONS_LOADING', payload: id });
        const res: any = await ReportGeneratorService.updateReportSections(reportSection);
        if(!res) {
            throw new Error('Error generating report section');
        }
        dispatch({
            type: 'GENERATE_REPORT_SECTIONS_SUCCESS',
            payload: { data: { content: res.value, prompt: reportSection?.Prompt, temperature: reportSection?.temperature } , id }
        });
    } catch(e) {
        dispatch({ type: 'GENERATE_REPORT_SECTIONS_ERROR', payload: id });
    }
}
